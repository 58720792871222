import { iosVhFix } from './utils/ios-vh-fix';
import { initAccordions } from './modules/accordion/init-accordion';

// ---------------------------------

window.addEventListener('DOMContentLoaded', () => {

  // Utils
  // ---------------------------------

  iosVhFix();

  // Modules
  initAccordions();

  function equalizeHeaderHeights() {
    const headers = document.querySelectorAll('.links__list p');
    const maxHeight = Math.max(...Array.from(headers).map(header => header.offsetHeight));
    headers.forEach(header => header.style.height = maxHeight + 'px');
  }

  window.addEventListener('load', equalizeHeaderHeights);
  window.addEventListener('resize', equalizeHeaderHeights);

  const cookieSetButton = document.querySelector('.cookie-settings');
  const cookieSubmitButton = document.querySelector('.cookie-submit');

  if (cookieSetButton) {
    cookieSetButton.addEventListener('click', function () {
      document.querySelector('.cookie').classList.add('visually-hidden')
      document.querySelector('.cookie-set').classList.remove('visually-hidden')
    })
  }

  const navigation = document.querySelector('.main-header__navigation');
  const cookieModal = document.querySelector('.modal-cookie');

  function clickOutside() {
    document.addEventListener('click', function(event) {
      const sidebar = document.getElementById('sidebar');
      const isClickInside = sidebar.contains(event.target);
      const isClickInsideModal = document.querySelector('.cookie').contains(event.target);
      const isClickInsideModalOpen = document.querySelector('.cookie-set').contains(event.target);
      const isIgnoredElement = Array.from(document.querySelectorAll('button, a, input, textarea, select')).some(el => el.contains(event.target));

      if (cookieModal)
       {
        if (!isClickInsideModal && !isClickInsideModalOpen && !isIgnoredElement && cookieModal.classList.contains('active')) {
          cookieModal.classList.remove('active');
          cookieModal.querySelector('.cookie').classList.add('visually-hidden');
          cookieModal.querySelector('.cookie-set').classList.add('visually-hidden');
          document.querySelector('.wrapper').classList.remove('dark');
        }
       }

       if (navigation)
       {
        if (!isClickInside && !isIgnoredElement && navigation.classList.contains('active')) {
          navigation.classList.remove('active');
          document.querySelector('.wrapper').classList.remove('dark');
        }
      }
    });
  }

  if (navigation) {
    clickOutside();
    if (navigation.classList.contains('active')) {
      clickOutside();
    }
  }

  if (cookieModal) {
    if (cookieModal.classList.contains('active')) {
      clickOutside();
      cookieModal.querySelector('.cookie').classList.remove('visually-hidden');
      document.querySelector('.wrapper').classList.add('dark');
    }
  }

  const cookieButtons = document.querySelector('.cookies-form-buttons');
  if (cookieButtons) {
    const allowButton = cookieButtons.querySelector('button[type="button"]');
    if (allowButton) {
      allowButton.addEventListener('click', function () {
        document.querySelectorAll('input[name="cookie-types"]').forEach(i => {
          i.checked = true;
        });
      })
    }

    const saveButtons = document.querySelectorAll('button[type="submit"]');
    if (saveButtons) {
      saveButtons.forEach(saveButton => {
        saveButton.addEventListener('click', function (evt) {
          evt.preventDefault()
          document.querySelector('.wrapper').classList.remove('dark');
          cookieModal.classList.remove('active');
          cookieModal.querySelector('.cookie').classList.add('visually-hidden');
          cookieModal.querySelector('.cookie-set').classList.add('visually-hidden');
        })
      });
    }
  }
});
